import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import startCase from 'lodash.startcase'

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
      allMdx {
        edges {
          node {
            excerpt
            frontmatter {
              title
              category
              path
              date(formatString: "MMMM DD, YYYY")
              listed
              wip
            }
          }
        }
      }
    }
  
`

const IndexPage = ({data}) => {
  const mdx = data.allMdx.edges.map(({node})=>node.frontmatter)

  console.log(mdx)

  const categories = Object.entries(mdx.reduce((categories,page)=>{
    if(!page.listed) return categories
    return {
    ...categories,
    [page.category||'uncategorised']:[...(categories[page.category||'uncategorised']||[]),page]
  }},{})).sort((a,b)=>(a[0]==='uncategorised'||a[0]>b[0]?1:-1))

  return (
  <Layout>
    <SEO title="Home" />
    {/*<StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />*/}
    <ul>
      {categories.map(
        ([categoryTitle,category])=><>
        <h2>{startCase(categoryTitle)}</h2>
        {category.filter(page=>page.listed).map((page,i)=><li key={`page-${i}`}><Link to={page.path}>{page.title}</Link></li>)}
        </>
      )}
    </ul>
  </Layout>
)}

export default IndexPage
